import React, { useCallback, useEffect, useState } from 'react'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import decode from 'jwt-decode'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import Loading from 'components/Loading'
import remoteLogger from 'utils/remoteLogger'

const APP_ACTIONS = Object.freeze({
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  MANAGE: 'manage',
})

const MessageContainer = styled.h3`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`

const AppActions = () => {
  const {
    loginWithRedirect,
    logout,
    getTokenSilently,
    auth0Loading,
  } = useAuth0()
  const { i18n, t } = useTranslation('appActions')

  const [app] = useQueryParam('app', StringParam)
  const [platform] = useQueryParam('platform', StringParam)
  const [action] = useQueryParam('action', StringParam)
  const [completed = false] = useQueryParam('completed', BooleanParam)

  const [message, setMessage] = useState(t('initialMessage'))

  const redirectToApp = (app, action, queryParams) => {
    remoteLogger.log('Redirecting to app', {
      app,
      action,
    })

    setMessage(t('returningToApp'))
    if (typeof window !== 'undefined') {
      const protocol =
        app === 'fluentworlds'
          ? process.env.GATSBY_FW_APP_PROTOCOL
          : process.env.GATSBY_PA_APP_PROTOCOL
      if (!protocol || typeof protocol === 'undefined' || protocol === '') {
        setMessage(t('unknownApp'))
        console.warn('Invalid app passed [%s]', app)
        return
      }

      const appRedirectURL = `${protocol}://${action}${queryParams}`
      window.location.replace(appRedirectURL)
    }
  }

  const sendTokenToApp = useCallback(async () => {
    const token = await getTokenSilently()

    // decode the token for logging purposes
    const decoded = decode(token)

    const loggerDetails = decoded
      ? { sub: decoded.sub, iat: decoded.iat, exp: decoded.exp }
      : { sub: undefined, iat: undefined, exp: undefined }
    remoteLogger.log('Sending token to app', loggerDetails)

    if (!token) {
      setMessage(t('tokenError'))
    }
    redirectToApp(app, action, `?token=${token}`)
    return token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, action, getTokenSilently, setMessage, t])

  useEffect(() => {
    remoteLogger.log('Checking the Status', {
      app,
      platform,
      action,
      completed,
      auth0Loading,
      queryParams:
        typeof window === 'undefined' ? 'SSR' : window.location.search,
    })

    console.log('Checking the status', {
      app,
      platform,
      action,
      completed,
      auth0Loading,
    })

    setMessage(t('checkingStatus'))
    if (auth0Loading) {
      return
    }

    if (!completed) {
      remoteLogger.log('Incomplete branch')
      switch (action) {
        case APP_ACTIONS.REGISTER:
        case APP_ACTIONS.LOGIN: {
          remoteLogger.log('Login Action')
          setMessage(t('actionStartLogin'))
          setTimeout(() => {
            loginWithRedirect({
              ...(action === APP_ACTIONS.REGISTER && { mode: 'signUp' }),
              ui_locales: i18n.language.substring(0, 2),
              redirect_uri:
                process.env.GATSBY_WEBSITE_URL +
                `/app-actions?action=${action}&completed=1&app=${app}&platform=${platform}`,
            })
          }, 1000)
          break
        }
        case APP_ACTIONS.LOGOUT: {
          remoteLogger.log('Logout Action')
          setMessage(t('actionStartLogout'))
          logout(
            `/app-actions?action=logout&completed=1&app=${app}&platform=${platform}`,
          )
          break
        }
        case APP_ACTIONS.MANAGE: {
          remoteLogger.log('Manage Action')
          setMessage(t('actionStartManage'))
          const accountManageUrl =
            process.env.GATSBY_WEBSITE_URL +
            `/account/?app=${app}&platform=${platform}&action=manage`
          loginWithRedirect({
            ui_locales: i18n.language.substring(0, 2),
            redirect_uri: accountManageUrl,
          })
          break
        }
        default: {
          remoteLogger.warn('Unexpected action: ', action)
          console.warn('Unexpected action: ', action)
          setMessage(t('actionStartDefault'))
          break
        }
      }
    } else {
      remoteLogger.log('Complete Branch')
      switch (action) {
        case APP_ACTIONS.REGISTER:
        case APP_ACTIONS.LOGIN: {
          remoteLogger.log('Login Action')
          setMessage(t('actionCompleteLogin'))
          sendTokenToApp()
          break
        }
        case APP_ACTIONS.LOGOUT: {
          remoteLogger.log('Logout Action')
          redirectToApp(app, action, '')
          break
        }
        default: {
          remoteLogger.warn('Unexpected Action', action)
          console.warn('Unexpected action: ', action)
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading])

  return (
    <>
      <Global styles={GlobalStyles} />
      <main>
        <MessageContainer>{message}</MessageContainer>
        <Loading height={'50vh'} />
      </main>
    </>
  )
}

export default AppActions
